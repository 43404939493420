<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2 d-flex flex-column flex-gap-2">
          <div class="d-flex flex-lg-row flex-column flex-gap-2">
            <!-- Order Status Filter -->
            <v-select
              class="v-select-filter w-100"
              v-model="selectedOrderStatus"
              :options="orderStatus"
              :reduce="item => item.value"
              :searchable="false"
              @input="getAllItems"
            />

            <!-- Order Feature Status Filter -->
            <div class="input-group w-100">
              <v-select
                class="v-select-filter flex-grow-1"
                placeholder="Order status.."
                v-model="selectedOrderFeatureStatus"
                :options="orderFeatureStatus"
                :reduce="item => item.value"
                :searchable="false"
                multiple
                @input="getAllItems"
              />

              <div v-if="selectedOrderFeatureStatus?.length >= 2" class="input-group-append">
                <div class="input-group-text p-0">
                  <select class="form-control font-weight-bold p-0 ml-1" v-model="orderFeatureStatusCondition" @change="getAllItems">
                    <option>AND</option>
                    <option>OR</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Order Channel Filter -->
            <v-select
              class="v-select-filter w-100"
              placeholder="Order channel.."
              v-model="selectedOrderChannel"
              :options="[
                { value: '0', label: 'MS' },
                { value: '1', label: 'SW' },
                { value: '2', label: 'APP' },
              ]"
              :reduce="o => o.value"
              :searchable="false"
              multiple
              @input="getAllItems"
            />
          </div>

          <div class="d-flex flex-lg-row flex-column flex-gap-2">
            <!-- Payment Methods Filter -->
            <v-select
              class="v-select-filter w-100"
              placeholder="Payment methods.."
              v-model="selectedPaymentMethod"
              :options="[
                { value: 'MPY', label: 'Menute Pay' },
                { value: 'AE', label: 'American Express' },
                { value: 'AP', label: 'AP' },
                { value: 'app', label: 'app' },
                { value: 'APY', label: 'Apple Pay' },
                { value: 'BC', label: 'Bitcoin' },
                { value: 'BK', label: 'Bancontact / Mister Cash (BK)' },
                { value: 'BKS', label: 'Bancontact / Mister Cash (BKS)' },
                { value: 'CC', label: 'Credit Card' },
                { value: 'CNT', label: 'Contant' },
                { value: 'GPY', label: 'Google Pay' },
                { value: 'IDL', label: 'iDEAL' },
                { value: 'MC', label: 'MasterCard' },
                { value: 'PP', label: 'PayPal' },
                { value: 'SOD', label: 'Sodexo' },
                { value: 'VCR', label: 'Sodexo Monizze' },
                { value: 'VS', label: 'Visa' }
              ]"
              :reduce="p => p.value"
              :searchable="false"
              multiple
              @input="getAllItems"
            />

            <!-- Restaurant Filter -->
            <v-select
              class="v-select-filter w-100"
              placeholder="Select restaurant.."
              v-model="selectedRestaurant"
              :options="restaurants"
              @input="getAllItems"
            />

            <!-- User Filter -->
            <v-select
              class="v-select-filter w-100"
              :placeholder="users.length == 0 ? 'Search for a user..' : 'Select or search for a user..'"
              v-model="selectedUser"
              :options="users"
              :searchable="true"
              :loading="searching && users.length == 0"
              @search="searchUsers"
              @input="getAllItems"
            >
              <template v-slot:option="user">
                <div class="d-flex align-items-center">
                  <div class="c-avatar mr-2">
                    <img :src="user.image_icon || user.gravatar" class="c-avatar-img">
                  </div>
                  <span class="small"><strong>{{ user.first_name }}</strong> #{{ user.id }}  ({{ user.email }})</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="user">
                {{ user.first_name }} #{{ user.id }}
              </template>
              <span slot="no-options">Type to search users..</span>
            </v-select>
          </div>

          <div class="d-flex flex-lg-row flex-column flex-gap-2">
            <!-- Delivery Service Filter -->
            <v-select
              class="v-select-filter w-100"
              placeholder="🚚 Delivery service.."
              v-model="deliveryServiceStatus"
              :options="[
                { value: 'all', label: 'All' },
                { value: 'not-confirmed', label: 'Order not confirmed' },
                { value: 'pending-assignment', label: 'Pending assignment' },
                { value: 'not-assigned', label: 'Not assigned' },
                { value: 'not-confirmed-by-courier', label: 'Not confirmed by courier' },
                { value: 'on-delivery', label: 'On delivery' },
              ]"
              :reduce="o => o.value"
              :searchable="false"
              @input="getAllItems"
            />

            <!-- Courier Filter -->
            <CourierSelect
              class="v-select-filter w-100"
              v-model="selectedCourier"
              :couriers="couriers"
              @input="getAllItems"
            />

            <!-- Referrer Params Filter -->
            <v-select
              class="v-select-filter w-100"
              placeholder="Referrer params.."
              v-model="selectedReferrerParams"
              :options="[
                { value: 'eo', label: 'EO (Google Ads)' },
                { value: 'mt', label: 'MT (Google Ads in Maps)' },
                { value: 'dc', label: 'DC (Display Campaign)' },
              ]"
              :reduce="o => o.value"
              :searchable="false"
              multiple
              @input="getAllItems"
            />
          </div>

          <div class="d-flex flex-lg-row flex-column flex-gap-2">
            <!-- Date Filter -->
            <v-date-picker v-model="date" is-range :model-config="{ 'type': 'string', 'mask': 'YYYY-MM-DD' }"
              class="date-range w-100">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="d-flex align-items-center justify-content-center">
                  <input :value="inputValue.start" v-on="inputEvents.start" placeholder="Start" class="form-control" />

                  <svg class="mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>

                  <div class="input-group">
                    <input :value="inputValue.end" v-on="inputEvents.end" placeholder="End" class="form-control" />
                    <div class="input-group-append">
                      <div class="input-group-text px-2" role="button" @click="date = null">❌</div>
                    </div>
                  </div>
                </div>
              </template>
            </v-date-picker>

            <!-- Search -->
            <CInput
              class="w-200 mb-0 search"
              type="search"
              placeholder="Search for something else ..."
              v-model="search"
              @input="searchFilter"
            />
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CProgress v-if="progressDown" :value="progress" size="sm"/>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No orders found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >

      <!-- ID -->
      <template #id="{item}">
        <td>
          <CLink
            :to="{ name: 'Order Details', params: { id: item.id }}"
            :class="getRestaurantConnectionStatus(item.restaurant.restaurant_device_link_with_device)"
            class="rounded p-2 font-weight-bold"
            v-c-tooltip="{
              html: true,
              content: item.restaurant._hr_datetime ? item.restaurant._hr_datetime : '-',
              placement: 'right'
            }"
          >
            #{{ item.id }}
          </CLink>
        </td>
      </template>

      <!-- Confirmation Status  -->
      <template #confirmation="{item}">
        <td>
          <div class="d-flex flex-gap-2 align-items-center">
            <div class="d-flex flex-column">
              <span v-if="item.roUnderControl == 1" class="flash-it" style="font-size: 1.875rem;"
                v-c-tooltip="{ content: item.control_taked_by, placement: 'top' }">👁️</span>

              <CIcon v-if="item.roConfirmationTime" :height="32" class="text-success"
                name="cil-check-alt"
                v-c-tooltip="{
                  html: true,
                  content: item.roConfirmationTime
                }"
              />
              <CLink v-else :to="{ name: 'Order Details', params: { id: item.id }}">
                <CIcon :height="32" class="text-warning" name="cil-x-circle" />
              </CLink>
            </div>

            <div>
              <span class="d-inline count bg-dark">{{ item._order_attr }}</span>
              <span class="d-block">{{ item.roStrId }}</span>
              <span class="d-block">
                {{ item.roPymntVfd == true ? '' : '* ' }}
                {{ item.roTotalPrice > 0 ? item.roTotalPrice : 0 | toCurrency }}
              </span>
              <span class="badge border border-dark">{{ item.roPaysWith }}</span>
              <span v-if="item.roPaysWith2" class="badge border border-dark ml-1">{{ item.roPaysWith2 }}</span>
            </div>
          </div>
        </td>
      </template>

      <!-- Code -->
      <!--
      <template #code="{item}">
        <td>
          <span class="d-inline count bg-dark px-1">{{ item._order_attr }}</span>
          <span class="d-block ml-1">{{ item.roStrId }}</span>
        </td>
      </template>
      -->

      <!-- Restaurant -->
      <template #restaurant="{item}">
        <td>
          <span class="badge border border-dark" v-html="checkDeviceType(item.restaurant)"></span>
          #{{ item.restaurant_id }}
          <CLink class="font-weight-bold" target="_blank" :to="{name: 'View Restaurant',params: { id: item.restaurant_id }}">
            {{ item.restaurant.restaurant_name }}
          </CLink>,
          {{ item.restaurant.restaurant_city }}
          <div class="restaurant-tel">
            <a v-if="item.restaurant.restaurant_tel" :href="'tel://'+item.restaurant.restaurant_tel">{{ item.restaurant.restaurant_tel }}</a>
            <a v-if="item.restaurant.restaurant_gsm" :href="'tel://'+item.restaurant.restaurant_gsm">{{ item.restaurant.restaurant_gsm }}</a>
            <a v-if="item.restaurant.eigenaar1_gsm" :href="'tel://'+item.restaurant.eigenaar1_gsm">{{ item.restaurant.eigenaar1_gsm }}</a>
            <a v-if="item.restaurant.eigenaar2_gsm" :href="'tel://'+item.restaurant.eigenaar2_gsm">{{ item.restaurant.eigenaar2_gsm }}</a>
          </div>
          <div v-c-tooltip="{ content: 'Restaurant Opening Time', placement: 'left'}" class="restaurant-opening-time" v-html="getRestaurantOpeningTime(item.restaurant)"></div>
          <div v-c-tooltip="{ content: 'Restaurant Delivery Time', placement: 'left'}"  class="restaurant-delivery-time" v-html="getRestaurantDeliveryTime(item.restaurant)"></div>
        </td>
      </template>

      <!-- Total Price -->
      <template #total_price="{item}">
        <td>
          <!-- {{ $order_status == '' && item.roOrdersBefore == 0 && item.roPaysWith == 'CNT' && item.roTotalPrice - item.roHandlingfee >= 50 && item.toTotalPrice > 0 ? 'class=blink_me' : '' }} -->
          {{ item.roPymntVfd == true ? '' : '* ' }}
          {{ item.roTotalPrice > 0 ? item.roTotalPrice - item.roHandlingfee : 0 | toCurrency }}
          <span class="badge border border-dark">{{ item.roPaysWith }}</span>
        </td>
      </template>

      <!-- Date Filter -->
      <template #created_at-filter>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text p-1 w-min">From</span>
          </div>
          <input
            id="date-filter-from"
            type="datetime-local"
            v-model="filters.orders.startDate"
            class="w-min"
          />
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text p-1 w-min">To</span>
          </div>
          <input
            id="date-filter-from"
            type="datetime-local"
            v-model="filters.orders.endDate"
            class="w-min"
          />
        </div>
      </template>

      <!-- Date (created_at) -->
      <!-- <template #created_at="{item}">
        <td>
          {{ item.created_at }}
        </td>
      </template> -->

      <!-- Approximate Delivery Time -->
      <template #roAproxDeliveryTime="{item}">
        <td>
          <dl class="mb-0">
            <dt>{{ item.created_at }}</dt>
            <dt class="font-weight-normal">Desired: <strong>{{ item.roDesiredDelivireyTime }}</strong></dt>
            <dt class="font-weight-normal">Approximate: <strong>{{ item.roAproxDeliveryTime || '∅' }}</strong></dt>

            <!-- Delivery Service -->
            <dt v-if="item.deliveryservice" class="font-weight-normal">
              <div class="d-flex flex-gap-2 border-top mt-1">
                <div class="d-flex flex-column flex-gap-2 align-items-center">
                  <span v-if="item.deliveryServiceOrderStatus?.status" class="flash-it" style="font-size: 2rem;"
                    v-c-tooltip="{ content: item.deliveryServiceOrderStatus.message, placement: 'bottom' }">⛔</span>
                  <div v-else-if="item.delivery?.courier" class="mt-1 c-avatar">
                    <img :src="item.delivery.courier?.image_icon || item.delivery.courier?.gravatar" class="c-avatar-img">
                  </div>
                  <div v-else-if="item.delivery?.dispatcher" class="mt-1 c-avatar bg-secondary">
                    <span style="font-size: 1.375rem;">👔</span>
                  </div>
                  <CIcon v-else name="cil-truck" size="2xl" class="text-dark" />
                </div>

                <div>
                  <div v-if="!(item.roConfirmationTime && item.delivery?.created_at_db)">
                    <!-- Not confirmed yet -->
                    <strong v-if="!item.roConfirmationTime" class="text-warning flash-it">Not confirmed yet!</strong>

                    <!-- Not yet assigned to courier -->
                    <div v-else-if="!(item.delivery?.created_at_db)">
                      <strong v-if="new Date(item.roAproxDeliveryDateToCourier) < new Date()" class="text-danger flash-it">
                        Not yet assigned!
                        Must be assigned by <strong>{{ $globalFunc.formatDateTime(item.roAproxDeliveryDateToCourier) }}</strong>
                      </strong>
                      <span v-else class="text-info">
                        On <strong>{{ $globalFunc.formatDateTime(item.roAproxDeliveryDateToCourier) }}</strong> will be assigned to the courier
                      </span>
                      <CLink class="d-block small mt-1" @click="assignToCourier(item)">
                        • Assign to courier<CIcon name="cil-transfer" size="sm" class="ml-1" />
                      </CLink>
                    </div>

                    <!-- Prevent order from being automatically assigned to a courier -->
                    <div class="d-flex align-items-center flex-gap-1">
                      <small :class="{'text-warning': item.courierCannotBeAssigned, 'text-muted': !item.courierCannotBeAssigned}">• Courier cannot be assigned:</small>
                      <CSwitch labelOn="Yes" labelOff="No" color="warning" size="sm" shape="pill"
                        :checked.sync="item.courierCannotBeAssigned"
                        @update:checked="() => updateDeliveryServiceDetails(item)" />
                    </div>
                  </div>

                  <!-- ** Sipariş bir kuryeye veya dispatchera atanmıştır ** -->
                  <div v-else class="order-courier-container text-success">
                    <!-- COURIER -->
                    <div v-if="item.delivery?.courier">
                      <div class="delivery-details">
                        {{ (nearestDeliveryEvent = getNearestDeliveryEvent(item.delivery), null) }}
                        <strong>{{ nearestDeliveryEvent?.key }}</strong>
                        <span class="d-block">📆 {{ nearestDeliveryEvent?.value.toLocaleString() }}</span>

                        <strong v-if="!item.delivery.confirmed" class="text-danger flash-it">
                          Not yet confirmed by {{ item.delivery.courier?.first_name }} #{{ item.delivery.courier?.id }}
                        </strong>
                      </div>
                      <div class="courier-details">
                        <span><strong>{{ item.delivery.courier?.first_name }}</strong> #{{ item.delivery.courier?.id }}</span>
                        <span><a :href="`mailto:${item.delivery.courier?.email}`">{{ item.delivery.courier?.email }}</a></span>
                        <span>📱<a :href="`tel:${item.delivery.courier?.mobile}`">{{ item.delivery.courier?.mobile }}</a></span>
                      </div>
                    </div>

                    <!-- DISPATCHER -->
                    <div v-else-if="item.delivery?.dispatcher && !item.delivery?.courier">
                      <div class="delivery-details">
                        <strong>Assigned to dispatcher {{ item.delivery.dispatcher.first_name }}
                          (#{{ item.delivery.dispatcher.id }}, 🕒{{ $globalFunc.formatTime(item.delivery.created_at_db) }})
                        </strong>
                        <strong class="d-block text-danger">
                          <span class="flash-it">⌛</span> Waiting for courier assignment by dispatcher...
                        </strong>
                      </div>
                      <div class="courier-details">
                        <span><strong>{{ item.delivery.dispatcher.first_name }}</strong> #{{ item.delivery.dispatcher.id }}</span>
                        <span><a :href="`mailto:${item.delivery.dispatcher.email}`">{{ item.delivery.dispatcher.email }}</a></span>
                        <span>📱<a :href="`tel:${item.delivery.dispatcher.mobile}`">{{ item.delivery.dispatcher.mobile }}</a></span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </dt>
          </dl>
        </td>
      </template>

      <!-- User -->
      <template #user_id="{item}">
        <td>
          <div>
            <template v-if="item.user">
              <CIcon v-if="item.user.lang == 'en'" class="flag" :width="24" :content="$options.flagSet['cifGb']" />
              <CIcon v-else class="flag" :height="14" :content="$options.flagSet['cif' + item.user.lang.charAt(0).toUpperCase() + item.user.lang.slice(1)]" />
              {{ item.user.lang.toUpperCase() }}
            </template>

            <CLink class="font-weight-bold" :to="{name: 'UserOrderList', params: { user_id: item.user_id }}">
              #{{ item.user_id }} {{ item.roUseName }}
            </CLink>
            <span class="d-inline count bg-success px-1 mr-1">{{ item.roOrdersBefore == 0 ? 'N' : item.roOrdersBefore }}</span>
          </div>

          <dl class="mt-2 mb-0">
            <dt class="font-weight-normal"><CIcon name="cil-at" class="mr-1" />{{ item.user ? item.user.email : 'User deleted.' }}</dt>
            <dt class="font-weight-normal"><CIcon name="cil-mobile" class="mr-1" />{{ item.roMobile }}</dt>
          </dl>
        </td>
      </template>

      <!-- User Name -->
      <!--
      <template #user_name="{item}">
        <td>
          <span class="d-inline count bg-success px-1 mr-1">{{ item.roOrdersBefore == 0 ? 'N' : item.roOrdersBefore }}</span>
          <CLink :to="{name: 'UserOrderList', params: { user_id: item.user_id }}">
            {{ item.roUseName }}
          </CLink>
        </td>
      </template>
      -->

      <!-- Postal Code -->
      <template #postal_code="{item}">
        <td>
          <dl>
            <dt><CIcon name="cil-location-pin" class="mr-1" />{{ item.roPostal_code }} ({{ item.roDistance }}) {{ item.roCity }}</dt>
            <dd style="margin-left: 20px">{{ item.roAddress }}</dd>
          </dl>
        </td>
      </template>

      <!-- Email -->
      <!--
      <template #email="{item}">
        <td>
          {{item.user ? item.user.email : 'User deleted.' }}
        </td>
      </template>
      -->

      <!-- Status -->
      <template #_status="{item}">
        <td>
          <ul class="mb-0 pl-0" v-if="item._status">
            <li v-for="status in item._status.filter(s => s !== null)" :key="status">
              {{ status }}
            </li>
          </ul>
        </td>
      </template>

      <!-- Referer -->
      <template #referer="{item}">
        <td>
          <!-- <ul class="mb-0 pl-0">
            <li>{{ item._is_mobile[2] }}</li>
            <li>{{ item.roReferrer }}</li>
            <li v-if="item.roMedium == 1">sw</li>
          </ul> -->
          <ul class="mb-0 pl-0 tile">
            <li>
              <CImg
                :src="refererIcon(item._is_mobile[2])"
                v-c-tooltip="{
                  html: true,
                  content: item._is_mobile[2]
                }"
              />
            </li>
            <li>
              <CImg
                src="/img/icons/link-referer.png"
                v-c-tooltip="{
                  html: true,
                  content: item.roReferrer
                }"
              />
            </li>
            <li v-if="item.roMedium == 1">
              <CImg
                src="/img/icons/sub-web.png"
                v-c-tooltip="{
                  html: true,
                  content: item.subweb_url
                }"
              />
            </li>

          </ul>
        </td>
      </template>
    </CDataTable>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center flex-gap-2">
          <!-- <h5 class="mr-3">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5> -->

          <div class="row text-center" style="min-width: 320px">
            <div class="col-3">
              <div class="text-value-lg"><span class="d-inline count px-1 bg-primary">{{ total }}</span></div>
              <div class="text-value-sm text-success">{{ parseFloat(totalPriceVerified) | toCurrency }}</div>
              <div class="text-uppercase small text-danger">{{ parseFloat(totalPriceDeleted) | toCurrency }}</div>
            </div>
            <template v-if="statistics">
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg">{{ statistics.orders.today.count }}</div>
                <div class="text-value-sm">{{ parseFloat(statistics.orders.today.total_price) | toCurrency }}</div>
                <div class="text-uppercase text-muted small">Today</div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg">{{ statistics.orders.today_deleted.count }}</div>
                <div class="text-uppercase text-muted small">Today Deleted</div>
              </div>
              <div class="c-vr"></div>

              <div class="col">
                <div class="text-value-lg">{{ statistics.orders.yesterday.count }}</div>
                <div class="text-value-sm">{{ parseFloat(statistics.orders.yesterday.total_price) | toCurrency }}</div>
                <div class="text-uppercase text-muted small">Yesterday</div>
              </div>
              <div class="c-vr"></div>
              <div class="col">
                <div class="text-value-lg">{{ statistics.orders.yesterday_deleted.count }}</div>
                <div class="text-uppercase text-muted small">Yesterday Deleted</div>
              </div>
            </template>
          </div>

          <div class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              v-show="pages > 1"
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <TransferOrRevokeOrder
      :courierId="null"
      :order="$globalFunc.extractKeys(selectedOrder, ['id', 'roDesiredDelivireyTime', 'roAproxDeliveryDateToCourier', 'roAddress', 'roCity', 'roPostal_code', 'roCountryId'])"
      :restaurant="$globalFunc.extractKeys(selectedOrder?.restaurant, ['id', 'restaurant_postcode'])"
      @transferred="onTableChange"
    />

  </CCardBody>
</template>

<script>
import { flagSet } from '@coreui/icons'
import { mapState } from 'vuex'
import _debounce from 'lodash/debounce';
import { DatePicker } from 'v-calendar'
import TransferOrRevokeOrder from "@/components/Courier/TransferOrRevokeOrder";
import CourierSelect from "@/components/Courier/CourierSelect";

export default {
  name: "BackendTable",
  flagSet,
  components: {
    'v-date-picker': DatePicker,
    TransferOrRevokeOrder,
    CourierSelect
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "id",                        label: "ID",                        filter: false, _style: "min-width: 50px;" },
        { key: "confirmation",              label: "Confirmation",              filter: false, _style: "min-width: 140px;" },
        { key: "restaurant",                label: "Restaurant",                filter: false, _style: "min-width: 360px;", sorter: false },
        { key: "roAproxDeliveryTime",       label: "Delivery",                  filter: false, _style: "min-width: 300px; width: 320px;" },
        { key: "user_id",                   label: "User",                      filter: false, _style: "min-width: 240px; width: 300px;" },
        { key: "postal_code",               label: "Address",                   filter: false, _style: "min-width: 240px; width: 300px;" },
        { key: "_status",                   label: "Status",                    filter: false, _style: "min-width: 180px;" },
        { key: "referer",                   label: "Referer",                   filter: false, _style: "min-width: 120px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      filteredColumn: null,

      loading: false,
      searching: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Filter işlemleri için
      allOrderStatus: ['all', 'today', 'yesterday'],
      allOrderFeatureStatus: ['invoiced', 'coupon', 'deleted'],
      restaurants: [],
      users: [],
      couriers: [],
      date: null,

      // Statistics
      statistics: null,
      totalPriceVerified: 0,
      totalPriceDeleted: 0,

      // Auto Update
      timer: null,
      autoUpdateInterval: 30000, // 30 sn
      progressDown : null,
      progress: 100,

      selectedOrder: null
    };
  },

  beforeDestroy () {
    // Cancel Auto Update
    this.autoUpdate(true);
  },

  async created () {
    await this.getStatistics();

    if (this.selectedOrderStatus === 'today') {
      this.autoUpdate();
    }
  },

  async beforeMount() {
    this.filterByDate = _debounce(async () => {
      await this.getAllItems();
    }, 1000);

    this.searchFilter = _debounce(async () => {
      if (this.selectedOrderStatus !== 'all')
        this.selectedOrderStatus = 'all';

      await this.getAllItems();
    }, 1000);

    this.searchUsers = _debounce(async (val) => {
      await this.getUsers(val);
    }, 500);
  },

  async mounted() {
    this.activePage =  this.queryPage;

    await this.getRestaurants();
    if(this.restaurantId) {
      this.selectedRestaurant = this.restaurants.find((item) => item.value == this.restaurantId)
    }

    if(this.userId) {
      await this.getUsers('i#' + this.userId);
      this.selectedUser = this.users.find((item) => item.id == this.userId)
    }

    await this.getCourierList();
    await this.getAllItems();

    // Change the type of table filter from text to search.
    var table_filter = document.querySelector("label.mfe-2 + input[type='text']");
    if (table_filter) {
      table_filter.type = "search";
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
    selectedOrderStatus() {
      this.autoUpdate(this.selectedOrderStatus !== 'today');
    },
    date(val) {
      this.filters.orders.startDate = val?.start;
      this.filters.orders.endDate = val?.end;
    },
    dateFilter() {
      this.filterByDate();
    }
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    restaurantId () { return this.$route.params.restaurant_id },
    userId () { return this.$route.params.user_id },
    orderStatus() {
      return this.allOrderStatus.map((item) => {
        return { label: this.$options.filters.ucfirst(item), value: item };
      });
    },
    orderFeatureStatus() {
      return this.allOrderFeatureStatus.map((item) => {
        return { label: this.$options.filters.ucfirst(item), value: item };
      });
    },

    // Filters
    search: {
      get () { return this.filters.orders.search },
      set (value) { this.filters.orders.search = value },
    },
    selectedOrderStatus: {
      get () { return this.filters.orders.selectedOrderStatus },
      set (value) { this.filters.orders.selectedOrderStatus = value || 'today' },
    },
    selectedOrderFeatureStatus: {
      get () { return this.filters.orders.selectedOrderFeatureStatus },
      set (value) { this.filters.orders.selectedOrderFeatureStatus = value },
    },
    orderFeatureStatusCondition: {
      get () { return this.filters.orders.orderFeatureStatusCondition },
      set (value) { this.filters.orders.orderFeatureStatusCondition = value },
    },
    selectedOrderChannel: {
      get () { return this.filters.orders.selectedOrderChannel },
      set (value) { this.filters.orders.selectedOrderChannel = value },
    },
    selectedReferrerParams: {
      get () { return this.filters.orders.selectedReferrerParams },
      set (value) { this.filters.orders.selectedReferrerParams = value },
    },
    selectedPaymentMethod: {
      get () { return this.filters.orders.selectedPaymentMethod },
      set (value) { this.filters.orders.selectedPaymentMethod = value },
    },
    deliveryServiceStatus: {
      get () { return this.filters.orders.deliveryServiceStatus },
      set (value) { this.filters.orders.deliveryServiceStatus = value },
    },
    selectedRestaurant: {
      get() { return this.filters.orders.selectedRestaurant },
      set(value) { this.filters.orders.selectedRestaurant = value },
    },
    selectedUser: {
      get() { return this.filters.orders.selectedUser },
      set(value) { this.filters.orders.selectedUser = value },
    },
    selectedCourier: {
      get() { return this.filters.orders.selectedCourier },
      set(value) { this.filters.orders.selectedCourier = value },
    },
    dateFilter() { return [this.filters.orders.startDate, this.filters.orders.endDate] }
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.ORDERS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          "order-status": this.selectedOrderStatus,
          "order-feature-status": this.selectedOrderFeatureStatus,
          "order-feature-status-condition": this.orderFeatureStatusCondition,
          "order-channel": this.selectedOrderChannel,
          "referrer-params": this.selectedReferrerParams,
          "payment-method": this.selectedPaymentMethod,
          "ds-status": this.deliveryServiceStatus,
          'restaurant-id': this.selectedRestaurant?.value,
          'user-id': this.selectedUser?.id,
          'courier-id': this.selectedCourier?.id,
          date_from: this.filters.orders.startDate,
          date_to: this.filters.orders.endDate
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
            this.totalPriceVerified = items.total_price_verified;
            this.totalPriceDeleted = items.total_price_deleted;

            const vm = this;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = '';
              item.deliveryServiceOrderStatus = vm.checkDeliveryServiceOrderStatus(item);

              if (item.roDeleted) {
                item._classes += item.roPymntVfd == true ? 'strikeout ' : 'opacity-40 ';
              }
              if (item.roPymntVfd != true) {
                item._classes += 'table-warning ';
              }
              if (item.roConfirmationTime == null) {
                item._classes += 'text-info ';
              }
              if (item.deliveryServiceOrderStatus?.status) {
                item._classes += 'table-danger-light ';
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getRestaurants() {
      const storedRestaurants = sessionStorage.getItem('restaurants');

      if (storedRestaurants) {
        this.restaurants = JSON.parse(storedRestaurants);
      } else {
        try {
          const response = await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME);
          this.restaurants = response.data;
          sessionStorage.setItem('restaurants', JSON.stringify(this.restaurants));
        } catch (error) {
          this.restaurants = [];
        }
      }
    },
    async getUsers(val) {
      if (val.length < 3) {
        return;
      }

      this.searching = true;
      try {
        const response = await this.$axios.get(this.$backend.USERS.GET_ALL, {
          params: {
            search: val,
            'get-relations': false,
            'except-couriers': false,
          }
        });
        this.users = response.data.data.map((el) => {
          return { ...el, label: `i#${el.id} ${el.first_name} ${el.email}` };
        });
      } catch (error) {
        this.users = [];
      } finally {
        this.searching = false;
      }
    },
    async getCourierList() {
      await this.$axios
        .get(this.$backend.COURIER.COURIERS.BASIC_LIST, {
          params: {
            'relationships': 'detail:id,user_id,parent_id,is_dispatcher',
            'exclude-subordinates': false,
          }
        })
        .then(({ data }) => {
          this.couriers = data;
        })
        .catch(() => this.couriers = [])
    },

    async getStatistics() {
      await this.$axios
        .get(this.$backend.REPORTS.ORDER)
        .then((response) => (this.statistics = response.data));
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.selectedOrder = null;
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },

    refererIcon(referer) {
      return `/img/icons/${['iPod', 'iPad', 'iPhone'].includes(referer) ? 'apple' : referer}.png`;
    },

    //#region From eo-main
    getRestaurantOpeningTime(restaurant) {
      var now = new Date();
      var today = now.getDay();

      var hours = restaurant.open_sunday;

      switch (today) {
        case 1: // monday
          hours = restaurant.open_monday;
          break;
        case 2:
          hours = restaurant.open_tuesday;
          break;
        case 3:
          hours = restaurant.open_wednesday;
          break;
        case 4:
          hours = restaurant.open_thursday;
          break;
        case 5:
          hours = restaurant.open_friday;
          break;
        case 6:
          hours = restaurant.open_saturday;
          break;
      }

      return (hours !== null && hours !== "")
        ? `<div class='d-inline'>${hours.replace(",", ", ")}</div>`
        : `<div class='d-inline text-danger'>No opening hours yet!</div>`;
    },
    getRestaurantDeliveryTime(restaurant) {
      if (restaurant.onlytakeaway) {
        return `<div class='d-inline text-danger'>Only takeout!</div>`
      }

      var now = new Date();
      var today = now.getDay();

      var hours = restaurant.deliver_sunday;

      switch (today) {
        case 1: // monday
          hours = restaurant.deliver_monday;
          break;
        case 2:
          hours = restaurant.deliver_tuesday;
          break;
        case 3:
          hours = restaurant.deliver_wednesday;
          break;
        case 4:
          hours = restaurant.deliver_thursday;
          break;
        case 5:
          hours = restaurant.deliver_friday;
          break;
        case 6:
          hours = restaurant.deliver_saturday;
          break;
      }

      return (hours !== null && hours !== "")
        ? `<div class='d-inline'>${hours}</div>`
        : `<div class='d-inline text-danger'>No delivery hours yet!</div>`;
    },
    checkDeviceType: function (restaurant) {
      var $device_link = restaurant.restaurant_device_link_with_device;

      if ($device_link != null && $device_link.device != null)
        return `${$device_link.device.software ? 'MS' : 'MB'}${$device_link.device.version != null ? '-' + $device_link.device.version : ''}-<span class="${$device_link.device.ws_status != 1 ? "text-danger" : "text-success"} font-weight-bold">${$device_link.device.ws_status != 1 ? 'OFF' : 'ON'}</span>`;

      return '∅';
    },
    //#endregion

    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedOrderStatus = 'today';
        this.selectedOrderFeatureStatus =
          this.selectedOrderChannel =
          this.selectedReferrerParams =
          this.selectedPaymentMethod =
          this.deliveryServiceStatus =
          this.selectedRestaurant =
          this.selectedUser =
          this.filters.orders.startDate =
          this.filters.orders.endDate = null;
        this.orderFeatureStatusCondition = 'AND';
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    autoUpdate (abort = false) {
      if (abort === true) {
        clearInterval(this.timer);
        clearInterval(this.progressDown);
        this.timer = null;
        this.progressDown = null;
        return;
      }

      this.progress = 100;

      this.progressDown = setInterval(() => {
        this.progress -= 100 / (this.autoUpdateInterval / 1000);
      }, 1000);

      // this.timer = setInterval(this.getAllItems, 4000);
      this.timer = setInterval(async () => {
        await Promise.all(
          [this.getAllItems(), this.getStatistics()]
        );
        this.progress = 100;
      }, this.autoUpdateInterval);

    },

    getRestaurantConnectionStatus(device) {
      var ws_status = device?.device.ws_status;
      var lastConnectionDate = device?.lastcheck;
      if (!lastConnectionDate) return "table-danger";

      const momentLastConn = this.moment(lastConnectionDate, "YYYY/MM/DD hh:mm:ss"),
        day_diff = this.moment().diff(momentLastConn, "days"),
        minute_diff = this.moment().diff(momentLastConn, "minutes");

      var checkTime = 5;

      if(ws_status == 1) {
        checkTime = 20;
      }

      return minute_diff <= checkTime
        ? "table-success"
        : day_diff <= 2
          ? "table-warning"
          : "table-danger";
    },

    getNearestDeliveryEvent(delivery) {
      return [
        { 'arrived_at_restaurant': 'The courier arrived at the restaurant.' },
        { 'picked_up': 'The courier picked up the order.' },
        { 'arrived_at_customer': 'The courier arrived at the customer.' },
        { 'delivered': 'The order delivered.' },
        { 'confirmed': 'The courier confirmed the order.' },
        { 'created_at_db': 'The order assigned to the courier.' }
      ]
        .reduce((nearest, key) => {
          const dkey = Object.keys(key)[0];
          const dateValue = new Date(delivery[dkey]);
          const nearestDate = new Date(nearest.value);

          return (!isNaN(dateValue) && (isNaN(nearestDate) || Math.abs(dateValue - new Date()) < Math.abs(nearestDate - new Date())))
            ? { 'key': key[dkey], value: dateValue }
            : nearest;
        }, { key: null, value: null });
    },

    // Menute Delivery Service
    checkDeliveryServiceOrderStatus(order) {
      if (!order.deliveryservice || !order.roConfirmationTime) {
        return { status: false };
      }

      const moment = this.moment;
      const roAproxDeliveryDateToCourier = moment(order.roAproxDeliveryDateToCourier);
      const now = moment();
      const timeToConfirm = 5; // minutes
      const timeToArriveAtRestaurant = 20; // minutes
      const timeToAssignCourierByDispatcher = 5; // minutes

      // created_at_db is not present
      if (!order.delivery?.created_at_db) {
        // Not yet assigned and must be assigned by...
        if (roAproxDeliveryDateToCourier.isBefore(now)) {
          return {
            status: true,
            message: `Not yet assigned! Must be assigned by ${roAproxDeliveryDateToCourier.format('YYYY-MM-DD HH:mm:ss')}.`
          };
        }

        return { status: false };
      }

      const createdAtDb = moment(order.delivery.created_at_db);
      const timeElapsed = now.diff(createdAtDb, 'minutes');

      if (order.delivery?.dispatcher && !order.delivery?.courier) {
        // The order has been assigned to a dispatcher, but the dispatcher has not assigned it to a courier within the specified time
        if (timeElapsed > timeToAssignCourierByDispatcher) {
          const expectedTime = createdAtDb.add(timeToAssignCourierByDispatcher, 'minutes').format('YYYY-MM-DD HH:mm');
          return {
            status: true,
            message: `The dispatcher has not assigned the order to a courier within the expected time (${expectedTime}).`
          };
        }
      } else if (order.delivery?.courier) {
        // Check if the courier is not yet confirmed and the confirmation time has passed
        if (!order.delivery.confirmed && timeElapsed > timeToConfirm) {
          return {
            status: true,
            message: `Courier has not confirmed the order yet. Confirmation time has passed.`
          };
        }

        // Check if the courier has not yet arrived at the restaurant
        if (!order.delivery.arrived_at_restaurant && timeElapsed > timeToArriveAtRestaurant) {
          return {
            status: true,
            message: `Courier has not yet arrived at the restaurant. Expected to arrive by ${createdAtDb.add(timeToArriveAtRestaurant, 'minutes').format('YYYY-MM-DD HH:mm:ss')}.`
          };
        }
      }

      return { status: false };
    },
    assignToCourier(order) {
      this.selectedOrder = order;
      this.$store.state.transferOrRevokeOrderShow = true
    },
    updateDeliveryServiceDetails(item) {
      this.$axios.post(
        this.$backend.ORDERS.UPDATE_DELIVERY_SERVICE.replace("{id}", item.id),
        { details: { courier_cannot_be_assigned: item.courierCannotBeAssigned } }
      )
        .catch(() => {
          item.courierCannotBeAssigned = !item.courierCannotBeAssigned;
        });
    },
  }
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
/* table-filter */
@media (min-width: 576px) {
  label.mfe-2 + input[type="text"],
  label.mfe-2 + input[type="search"] {
    width: 100% !important;
  }
}
.opacity-40 {
  opacity: 0.40;
}

.order-status-group .form-check-label {
  text-align: center;
  width: 100%;
  cursor: pointer;
  padding: 0.275rem;
  border: 1px solid rgba(60,60,60,.26);
  border-radius: 4px;
}

.order-status-group input[type=radio]:checked + .form-check-label {
  /* table-warning */
  color: #0a0b18;
  background-color: #fde9bd;
  border-color: #fcd685;
}

.search input {
  font-size: 1rem !important;
}

.date-range input {
  font-size: 1rem;
  font-weight: bold;
  color: #f9b115 !important;
}
.date-range input::placeholder {
  font-weight: normal !important;
}
.date-range svg {
  width: 64px;
}
</style>

<style scoped>
.tile li {
  display: inline;
  float: left;
}
.tile li:not(:last-child) {
   margin-right: 5px;
}
.tile li > img {
  width: 24px;
}

.badge {
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0;
}
.badge-first {
  border-right: 1px solid #fff;
}
.badge.notify {
  position: absolute;
  left: 25px;
  top: 10px;
  border-radius: 30px;
}

.restaurant-tel a:first-child::before {
  content: "☏ ";
  white-space: nowrap;
  margin-right: 0.375rem;
}
.restaurant-tel a + a::before {
  content: " | ";
}
.restaurant-opening-time::before {
  content: "🕔 "; /* &#128340; */
}

.restaurant-delivery-time::before {
  content: "🚚 "; /* &#128340; */
}
.flag {
  position: relative;
  bottom: 2px;
}

/* Date Filter */
span.w-min {
  width: 48px;
}
input.w-min {
  width: 140px;
}
input[type="date" i],
input[type="datetime-local" i] {
  color: #f9b115;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  padding-left: 4px;
  margin: 0;
}

/* Auto Update */
.progress-sm {
  height: 2px;
  border-radius: 0;
}
</style>

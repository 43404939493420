<template>
  <v-select
    :value="value"
    :options="rootCouriers"
    :reduce="(courier) => courier"
    label="first_name"
    placeholder="Courier.."
    :searchable="true"
    @input="$emit('input', $event)"
  >
    <template #selected-option="courier">
      <div class="selected-courier">
        <template v-if="courier.detail?.parent_id">
          <small class="text-muted">{{ getParentName(courier) }} › </small>
          <span>👷</span>{{ courier.first_name }}
        </template>
        <template v-else>
          <span v-if="courier.detail?.is_dispatcher">👔</span>
          {{ courier.first_name }}
        </template>
      </div>
    </template>

    <template #option="courier">
      <div class="courier-parent">
        <div class="d-flex align-items-center">
          <CImg :src="courier.image_icon || courier.gravatar" class="courier-avatar" placeholderColor="lightgray" />
          <span>{{ courier.first_name }} #{{ courier.id }}</span>
          <span v-if="getChildCouriers(courier).length" class="ms-1">▾</span>
          <span v-if="courier.detail?.is_dispatcher" class="ms-1 dot bg-secondary">👔</span>
        </div>
        <!-- <small v-if="courier.email" class="text-muted d-block">{{ courier.email }}</small> -->
      </div>

      <template v-for="child in getChildCouriers(courier)">
        <div class="courier-child" @mousedown.stop="handleOptionSelected(child)">
          <div class="d-flex align-items-center">
            <span class="tree-line">├</span>
            <div class="d-flex align-items-center">
              <CImg :src="child.image_icon || child.gravatar" class="courier-avatar" placeholderColor="lightgray" />
              <div>
                <div>{{ child.first_name }} #{{ child.id }}</div>
                <!-- <small v-if="child.email" class="text-muted d-block">{{ child.email }}</small> -->
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>

    <template #no-options>
      <div class="text-muted p-2">No couriers found</div>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CourierSelect',
  props: {
    value: { type: Object, default: null },
    couriers: { type: Array, required: true },
  },
  computed: {
    rootCouriers() {
      // Root kuryeleri filtrele (parent_id null olanlar)
      return this.couriers.filter(courier => !courier.detail?.parent_id);
    }
  },
  methods: {
    handleOptionSelected(courier) {
      this.$emit('input', courier);
    },
    getChildCouriers(parent) {
      return this.couriers.filter(courier => courier.detail?.parent_id === parent.id);
    },
    getParentName(courier) {
      if (!courier.detail?.parent_id) return '';
      const parent = this.couriers.find(c => c.id === courier.detail?.parent_id);
      return parent ? parent.first_name : '';
    }
  }
};
</script>

<style scoped>
.courier-parent {
  font-weight: 600;
  color: #5856d6;
  padding: 4px 0;
}

.courier-parent.not-selectable {
  cursor: default;
}

.courier-child {
  padding: 4px 0;
  margin-left: 0;
  cursor: pointer;
}

.courier-child:hover {
  background: rgba(88, 86, 214, 0.05);
}

.tree-line {
  color: #5856d6;
  margin-right: 8px;
  opacity: 0.5;
}

.courier-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.selected-courier {
  display: flex;
  align-items: center;
  gap: 4px;
}

:deep(.vs__dropdown-option) {
  padding: 4px 8px;
}

:deep(.vs__dropdown-option--highlight) {
  background: rgba(88, 86, 214, 0.1);
  color: #5856d6;
}
</style>
